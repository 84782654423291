<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, provide, ref, watch } from 'vue';
import useSettingSideBarStore from '../hooks/useSettingSideBarStore';
import ElementSettingsGroup from './ElementSettingsGroup.vue';
import type { ElementSettingProps } from '@gem/element-setting-ui';
import type { ScreenType, SwatchesManagerModelData } from '@gem/common';
import { useFlattenControlUI } from './hooks/useFlattenControlUI';
import GroupLayout from './layout/GroupLayout.vue';
import { ELEMENT_SETTINGS_PROVIDE_KEY } from '../consts/provideKeys';

const props = defineProps<ElementSettingProps>();

const emit = defineEmits<{
  (event: 'changeDevice', screenId: ScreenType): void;
  (event: 'goToPricing', value?: string): void;
  (event: 'openSwatchesManager', value?: SwatchesManagerModelData): void;
  (event: 'toggleEditorDisableClick', value?: boolean): void;
  (event: 'triggerScrollTop'): void;
}>();
const settingSideBarStore = useSettingSideBarStore();

const setSideBarStoreValue = () => {
  settingSideBarStore.setControlComponents(props.controlComponents);
  settingSideBarStore.setEditingComponentUid(props.editingComponentUid);
  settingSideBarStore.setCurrentDevice(props.currentDevice);
  settingSideBarStore.setEditingPageType(props.editingPageType);
  settingSideBarStore.setPickedDynamicProduct(props?.pickedDynamicProduct);
  settingSideBarStore.setPickedDynamicCollection(props?.pickedDynamicCollection);
  settingSideBarStore.setThemePageStatus(props?.themePageStatus);
  settingSideBarStore.setGlobalStyle(props?.globalStyles);
  settingSideBarStore.setShopPlan(props?.shopPlan);
  settingSideBarStore.setShopifyDomain(props?.shopifyDomain);
  settingSideBarStore.setElementData(props?.elementData);
  settingSideBarStore.setSection(props?.section);
};
setSideBarStoreValue();
const { groupSettingsFlatten } = useFlattenControlUI();
const displaySettingGroups = computed(() => {
  return props.settings.filter((item) => {
    const groupControlsDisplay = groupSettingsFlatten(item).filter((item) => !item.hide);
    return !item.hide && groupControlsDisplay.length;
  });
});

const goToPricing = computed(() => settingSideBarStore.goToPricing);
const currentDevice = computed(() => settingSideBarStore.currentDevice);
const swatchesManagerData = computed(() => settingSideBarStore.swatchesManagerData);
const hasLayoutGroup = computed(() => {
  return !!displaySettingGroups.value.find((item) => item.label?.en?.toLocaleLowerCase() === 'layout');
});
const hasStyleLabel = computed(() => {
  return !!displaySettingGroups.value.find((item) => item.label?.en?.toLocaleLowerCase() === 'style');
});
const showProductSource = computed(() => {
  return props.isProductChildElement && props.activeTabId === 'setting';
});

const showArticleSource = computed(() => {
  return props.isArticleChildElement && props.activeTabId === 'setting';
});

watch(
  () => props,
  () => {
    setSideBarStoreValue();
  },
  { deep: true },
);

// const comboActiveID = computed(() => settingSideBarStore.comboActiveID);
// watch(comboActiveID, (newValue) => {
//   emit('toggleEditorDisableClick', !!newValue);
// });

watch(goToPricing, (newValue) => {
  if (newValue) {
    emit('goToPricing', newValue);
    settingSideBarStore.setGoToPricing(undefined);
  }
});

watch(currentDevice, (newValue, oldValue) => {
  if (newValue != oldValue) {
    emit('changeDevice', newValue);
  }
});

watch(
  swatchesManagerData,
  (newValue) => {
    if (!newValue) return;
    emit('openSwatchesManager', newValue);
    settingSideBarStore.setSwatchesManagerData(undefined);
  },
  { deep: true },
);

provide(ELEMENT_SETTINGS_PROVIDE_KEY, {
  controlChange: props.controlChange,
  controlOnChange: props.controlOnChange,
});

const resizeObserver = ref<ResizeObserver>();

onMounted(() => {
  const element = document.getElementById('#setting-panel');
  const scrollbar = document.getElementById('scroll-bar');
  let currentHeight = element?.scrollHeight || 0;
  const scrollbarHeight = scrollbar?.scrollHeight || 0;
  if (!element) return;
  resizeObserver.value = new ResizeObserver(() => {
    currentHeight = element?.scrollHeight || 0;
    if (currentHeight < scrollbarHeight - 65) {
      emit('triggerScrollTop');
    }
  });
  resizeObserver.value.observe(element);
});

onBeforeUnmount(() => {
  emit('toggleEditorDisableClick', false);
  if (resizeObserver.value) {
    resizeObserver.value.disconnect();
  }
});
</script>

<template>
  <div id="#setting-panel" class="gemx-controls bg-dark-500">
    <div class="flex flex-col">
      <template v-for="(group, index) in displaySettingGroups" :key="`${index}`">
        <GroupLayout
          v-if="showProductSource && !hasLayoutGroup && !hasStyleLabel && index === 0"
          :data="{ label: { en: 'Product Source' } }">
          <template v-if="isProductChildElement && activeTabId === 'setting'" #controls>
            <component :is="controlProductSource" :hidden-label="true" :component="elementData" />
          </template>
        </GroupLayout>
        <GroupLayout
          v-if="showArticleSource && !hasLayoutGroup && !hasStyleLabel && index === 0"
          :data="{ label: { en: 'Article Source' } }">
          <template v-if="isArticleChildElement && activeTabId === 'setting'" #controls>
            <component :is="controlArticleSource" :hidden-label="true" :component="elementData" />
          </template>
        </GroupLayout>
        <ElementSettingsGroup :data="group" :no-border="index === displaySettingGroups.length - 1" />
        <GroupLayout
          v-if="
            (group.label?.en?.toLocaleLowerCase() === 'layout' || group.label?.en?.toLocaleLowerCase() === 'style') &&
            showProductSource
          "
          :data="{ label: { en: 'Product Source' } }">
          <template v-if="isProductChildElement && activeTabId === 'setting'" #controls>
            <component :is="controlProductSource" :hidden-label="true" :component="elementData" />
          </template>
        </GroupLayout>
        <GroupLayout
          v-if="
            (group.label?.en?.toLocaleLowerCase() === 'layout' || group.label?.en?.toLocaleLowerCase() === 'style') &&
            showArticleSource
          "
          :data="{ label: { en: 'Article Source' } }">
          <template v-if="isArticleChildElement && activeTabId === 'setting'" #controls>
            <component :is="controlArticleSource" :hidden-label="true" :component="elementData" />
          </template>
        </GroupLayout>
      </template>
    </div>
  </div>
</template>
