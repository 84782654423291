import type { Unit } from '@gem/element-setting-ui';
import type { ComputedRef } from 'vue';
import { computed, ref, watch } from 'vue';
import { useInputValidateValue } from './useInputValidateValue';

type Props = {
  units: Unit[];
  value?: string;
  max?: number;
  min?: number;
  defaultValue?: string;
};

export const useInputUnit = (data: ComputedRef<Props>) => {
  const { modifyValueByMinMax, isNoModifyValue } = useInputValidateValue(data.value);

  const inputRef = ref<any>();
  const valueChange = ref(getValueChangeByDefault(data.value.value));
  const currentUnit = ref(getUnitFromValue(valueChange.value));
  const inputValue = ref(getInputValue(valueChange.value));
  const hasDefault = computed(() => !!data.value.defaultValue);

  const updateUnit = (unit: Unit) => {
    currentUnit.value = unit;
    updateValueChange();
  };

  const updateValueChange = (options?: { forceValue?: string; isUpdateInput?: boolean }) => {
    if (options?.forceValue) {
      updateInputValue(options?.forceValue);
    } else {
      valueChange.value = getValueChangeFromInput();
      if (hasDefault.value && !valueChange.value) {
        valueChange.value = 'default';
      }
      options?.isUpdateInput && updateInputValue(valueChange.value);
    }
  };

  function updateInputValue(valueWithUnit?: string) {
    inputValue.value = getInputValue(getValueChangeByDefault(valueWithUnit));
    inputRef.value?.updateValue(inputValue.value);
  }

  function getValueChangeFromInput() {
    const currentInputValue = modifyValueByMinMax(inputRef.value?.getValue());
    return isNoModifyValue(currentInputValue) || !currentInputValue
      ? currentInputValue
      : `${currentInputValue}${currentUnit.value}`;
  }

  function getInputValue(value?: string) {
    return isNoModifyValue(value) ? value : parseUnit(value)[0];
  }

  function getUnitFromValue(value?: string) {
    if (value === undefined) return undefined;
    const unit = parseUnit(value)[1] as Unit;

    if (!data.value.units.includes(unit)) return '';
    return data.value.units[0];
  }

  function parseUnit(key?: string): [number | string | undefined, string] {
    if (key === undefined) return [undefined, ''];
    if (key.toLowerCase() === 'auto') return ['Auto', ''];
    if (key.toLowerCase() === 'original') return ['Original', ''];
    if (key.toLowerCase() === 'any') return ['Any', ''];
    const parseVal = parseFloat(key);
    const num = isNaN(parseVal) ? undefined : parseVal;
    const unit = (key.match(/[\d.\-+]*\s*(.*)/) as RegExpMatchArray)[1];

    return [num, unit];
  }

  function getValueChangeByDefault(value?: string) {
    return (!value || value === 'default') && data.value.defaultValue ? data.value.defaultValue : value;
  }

  watch(
    () => data.value,
    () => {
      console.log(data);
      updateInputValue(data.value.value);
      valueChange.value = getValueChangeByDefault(data.value.value);
      currentUnit.value = getUnitFromValue(valueChange.value);
    },
  );

  return {
    inputRef,
    currentUnit,
    valueChange,
    inputValue,
    updateUnit,
    updateValueChange,
    updateInputValue,
  };
};
