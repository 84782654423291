import { ref, watch, computed, onMounted } from 'vue';
import type { PickIconProps } from '../types';
import { useInfiniteScroll, watchDebounced, useVirtualList, useDebounceFn } from '@vueuse/core';
import type { ComboProps } from '../../../sidebar-setting/types';

export function useIconPicker(props: PickIconProps & ComboProps, emit: any) {
  const styleOptions = [
    {
      title: 'All style',
      value: 'ALL',
      id: 'ALL',
    },
    {
      title: 'Light',
      value: 'LIGHT',
      id: 'LIGHT',
      icon: 'gp-star-light',
    },
    {
      title: 'Regular',
      value: 'REGULAR',
      id: 'REGULAR',
      icon: 'gp-star-regular',
    },
    {
      title: 'Bold',
      value: 'BOLD',
      id: 'BOLD',
      icon: 'gp-star-bold',
    },
    {
      title: 'Filled',
      value: 'FILLED',
      id: 'FILLED',
      icon: 'gp-star-filled',
    },
    {
      title: 'Uploaded',
      value: 'UPLOADED',
      id: 'UPLOADED',
      icon: 'gp-upload',
    },
  ];
  const fixedValue = `<svg height="20" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill="currentColor" data-id="508414815326568808">
          <path fill="currentColor" strokelinecap="round" strokelinejoin="round" d="M234.29,114.85l-45,38.83L203,211.75a16.4,16.4,0,0,1-24.5,17.82L128,198.49,77.47,229.57A16.4,16.4,0,0,1,53,211.75l13.76-58.07-45-38.83A16.46,16.46,0,0,1,31.08,86l59-4.76,22.76-55.08a16.36,16.36,0,0,1,30.27,0l22.75,55.08,59,4.76a16.46,16.46,0,0,1,9.37,28.86Z"></path></svg>`;

  const selectedItem = ref({ id: '', value: '' });
  const realValue = ref(props.value);
  const searchValue = ref('');
  const modeDelete = ref<boolean>(false);
  const deleteIds = ref<string[]>([]);
  const wrapperRefSub = ref<HTMLInputElement | null>(null);

  const defaultValue = computed(() => props.compoDefaultValue ?? fixedValue);
  const comboSvg = computed(() => {
    return realValue.value?.replace(/width="[^"]+"/, 'width="20px"').replace(/height="[^"]+"/, 'height="20px"');
  });
  const isShowUploadedIcons = computed(() => props.styleIcon === 'UPLOADED' || props.styleIcon === 'ALL');
  const isUploadedActive = computed(() => !!props.styleIcon && props.styleIcon === 'UPLOADED');

  const dataIconSystem = computed(() => {
    return props.data?.map((record) => {
      return {
        ...record,
        value: record.data,
      };
    });
  });

  const filteredDataIconSystem = computed(() => {
    if (isUploadedActive.value) return [];
    return dataIconSystem.value ?? [];
  });
  const suggestedIcons = computed(() => props.suggestedIcons ?? []);
  const isShowSuggestedIcons = computed(() => suggestedIcons.value.length > 0);

  const chunkedDataIconSystem = computed(() => {
    const chunkSize = 5;
    const chunks = [];
    for (let i = 0; i < filteredDataIconSystem.value.length; i += chunkSize) {
      chunks.push(filteredDataIconSystem.value.slice(i, i + chunkSize));
    }
    return chunks;
  });

  const { list, containerProps, wrapperProps } = useVirtualList(chunkedDataIconSystem, {
    itemHeight: 43,
    overscan: 5,
  });
  const isEmptyIcon = computed(
    () => filteredDataIconSystem.value.length === 0 && props.iconUploaded.length === 0 && !isUploadedActive.value,
  );
  const isShowIconSystemList = computed(() => !isUploadedActive.value && list.value.length);
  const contentDisplay = computed(() => props.customLabel ?? 'Icon');

  const onShowMoreIconSystem = () => {
    emit('show-more', 'system');
  };

  const change = (svgIcon: string, iconSource?: string) => {
    realValue.value = svgIcon;
    emit('controlChange', svgIcon, iconSource);
  };

  const setActiveUploadedStyle = () => {
    emit('on-change-type', 'UPLOADED');
  };

  const onSelectStyle = (val: string) => {
    emit('on-change-type', val);
    modeDelete.value = false;
    deleteIds.value = [];
  };

  const onSearch = (val: string) => {
    searchValue.value = val;
  };

  const handleUpload = (formData: FormData) => {
    emit('on-upload', formData);
  };

  const onShowMoreIconUpload = () => {
    emit('show-more', 'uploaded');
  };

  const onSelectIcon = (value: string, id: string, source?: string) => {
    if (source === 'system') {
      selectedItem.value.value =
        `<svg height="20" width="20" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 256 256" fill="currentColor" data-id="${id}">
              <path fill="currentColor" strokeLinecap="round" strokeLinejoin="round" fill="currentColor" d="` +
        value +
        `" /></svg>`;
    } else {
      selectedItem.value.value = value;
    }
    selectedItem.value.id = id;
    change(selectedItem.value.value);
  };

  const handleSetDefaultValue = () => {
    if (realValue.value) return;

    selectedItem.value.value = defaultValue.value;
    change(selectedItem.value.value);
  };

  const handleClear = () => {
    selectedItem.value = { id: '', value: '' };
    change('');
  };

  const postUploadImage = (urlImage: File) => {
    const formData = new FormData();
    formData.append('file', urlImage);
    formData.append('fileType', 'ICON');
    handleUpload(formData);
  };

  const handleChangeFileFromUploader = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (!target.files?.length) return;
    const file = target.files.item(0);
    if (!file) return;
    if (!props.allowedFiles?.includes(file.type)) {
      emit('show-error', 'Supported format: SVG');
      clearInputFile();
      return;
    }
    if (props.maximumSize && file.size > props.maximumSize) {
      emit('show-error', 'File size limit: 100KB');
      clearInputFile();
      return;
    }
    postUploadImage(file);
    clearInputFile();
    setActiveUploadedStyle();
  };

  const clearInputFile = () => {
    if (wrapperRefSub.value?.value) {
      wrapperRefSub.value.value = '';
    }
    resetScroll();
  };

  const onBulkSelectIcon = (iconId: string) => {
    if (deleteIds.value.includes(iconId)) {
      deleteIds.value.splice(deleteIds.value.indexOf(iconId), 1);
    } else {
      deleteIds.value.push(iconId);
    }
    modeDelete.value = deleteIds.value.length > 0;
  };

  const onCancelDeleteIcon = () => {
    modeDelete.value = false;
    deleteIds.value = [];
  };

  const onBulkDeleteIcon = async () => {
    await deleteIcons();
    onCancelDeleteIcon();
  };

  const deleteIcons = async () => {
    emit('on-delete-icon', deleteIds.value);
  };

  const resetScroll = () => {
    if (containerProps.ref.value) {
      containerProps.ref.value.scrollTop = 0;
    }
  };

  const loadMore = useDebounceFn(() => {
    if (props.loading) return;
    emit('show-more', isUploadedActive.value ? 'uploaded' : 'system');
  }, 300);

  useInfiniteScroll(
    containerProps.ref,
    () => {
      loadMore();
    },
    { distance: 10 },
  );

  const setSelectedItem = () => {
    selectedItem.value.id =
      realValue.value
        ?.match(/data-id="\d+"/)
        ?.at(0)
        ?.match(/\d+/)
        ?.at(0) || '';
  };

  watch(
    () => props.value,
    (val) => {
      realValue.value = val;
    },
  );

  watch(
    () => props.searchKeyword,
    () => {
      searchValue.value = props.searchKeyword || '';
    },
  );

  watch(realValue, () => {
    setSelectedItem();
  });

  watchDebounced(
    searchValue,
    () => {
      emit('on-search', searchValue.value);
      resetScroll();
    },
    { debounce: 300 },
  );

  onMounted(() => {
    searchValue.value = props.searchKeyword || '';
    setSelectedItem();
  });

  return {
    list,
    deleteIds,
    comboSvg,
    modeDelete,
    searchValue,
    isEmptyIcon,
    styleOptions,
    wrapperProps,
    selectedItem,
    wrapperRefSub,
    containerProps,
    suggestedIcons,
    isUploadedActive,
    isShowUploadedIcons,
    isShowIconSystemList,
    isShowSuggestedIcons,
    contentDisplay,
    onSelectIcon,
    onSearch,
    handleClear,
    handleUpload,
    onSelectStyle,
    onBulkSelectIcon,
    onBulkDeleteIcon,
    onCancelDeleteIcon,
    onShowMoreIconUpload,
    onShowMoreIconSystem,
    handleSetDefaultValue,
    setActiveUploadedStyle,
    handleChangeFileFromUploader,
  };
}
