<script lang="ts" setup>
import { getLabel } from '../common';
import { InventoryStatus, CompareType } from '../constant';
import PopoverContent from '../Wrapper/PopoverContent.vue';
import RangeInput from '../common/RangeInput.vue';
import CompareTypeSelect from '../common/CompareTypeSelect.vue';
import InputBadge from '../common/InputBadge.vue';
import { InventoryStatusEmit, InventoryStatusProps } from './types';
import { useInventoryStatus } from './hooks/useInventoryStatus';

const props = defineProps<InventoryStatusProps>();
const emit = defineEmits<InventoryStatusEmit>();
const { onChangeType, onChangeQuantityType, onChangeQuantityValue, message, min, isOpenDropdown, type, quantityType } =
  useInventoryStatus(props, emit);
</script>

<template>
  <PopoverContent title="When">
    <div class="w-[140px]">
      <GSelect
        :active-id="type"
        :options="[
          { id: InventoryStatus.IN_STOCK, name: getLabel(InventoryStatus.IN_STOCK) },
          { id: InventoryStatus.OUT_OF_STOCK, name: getLabel(InventoryStatus.OUT_OF_STOCK) },
        ]"
        @select="onChangeType">
        <template #button-content>
          <span>{{ getLabel(type) }}</span>
        </template>
      </GSelect>
    </div>
    <template #tooltip>
      <GTooltip placement="top" content-class="w-[158px] !whitespace-normal break-words">
        <GBaseIcon name="info-solid" width="20" height="20" viewBox="0 0 16 16" class="!text-text-dark-100" />
        <template #content> Only work with product enable “Track inventory” </template>
      </GTooltip>
    </template>
  </PopoverContent>
  <PopoverContent v-if="type === InventoryStatus.IN_STOCK" title="Quantity">
    <CompareTypeSelect :quantity-type="quantityType" @on-change="onChangeQuantityType" />
    <RangeInput
      v-if="quantityType === CompareType.BETWEEN"
      :from="data.from"
      :to="data.to"
      :min="1"
      @on-update-value="onChangeQuantityValue" />
    <InputBadge v-else id="from" :value="data.from" :min="min" @on-change="onChangeQuantityValue" />
    <template #message>
      <div v-if="message" class="text-12 flex items-start gap-8 text-red-200">
        <g-base-icon name="info" width="20" height="20" viewBox="0 0 16 16" />
        <p>{{ message }}</p>
      </div>
    </template>
  </PopoverContent>
</template>
