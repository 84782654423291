<script setup lang="ts">
import { SettingLayout, useControlChange, type SettingUIControl } from '@gem/element-setting-ui';
import useControlData from '../hooks/useControlData';
import SettingTitle from './SettingTitle.vue';
import ControlLocked from './ControlLocked.vue';
import type { PlanPageType, SwatchesOptionType } from '@gem/common';
import { computed } from 'vue';
import useSettingSideBarStore from '../hooks/useSettingSideBarStore';

type ControlSettingProps = {
  data: SettingUIControl;
  hiddenDevice?: boolean;
};

const props = defineProps<ControlSettingProps>();

const settingSideBarStore = useSettingSideBarStore();

const currentDevice = computed(() => settingSideBarStore.currentDevice);
const shopPlan = computed(() => settingSideBarStore.shopPlan);
const controlData = computed(() => props.data);
const hasDevices = computed(() => !!props.data?.mapTo?.control.devices);

const isFullWidth = computed(() =>
  props.data?.options?.fullWidth === undefined ? true : props.data?.options?.fullWidth,
);

const isLockedControl = computed(() => {
  const lockedOnPlans = props.data.options?.lockedOnPlans;
  if (lockedOnPlans && shopPlan.value) {
    return lockedOnPlans.includes(shopPlan.value as PlanPageType);
  }

  return false;
});

const nearestSupportedPlan = computed(() => {
  return props.data.options?.nearestSupportedPlan;
});

const CONTROL_AUTO_WIDTH = ['switch'];
const isControlAutoWidth = computed(() => CONTROL_AUTO_WIDTH.includes(props.data.mapTo?.control.type || ''));

const { controlName, controlProps } = useControlData({
  controlData: controlData,
});

const controlChangeData = computed(() => controlProps.value.controlChangeData);

const { change, onChange } = useControlChange(controlChangeData);

const handleControlChange = (controlId: string, val?: any) => {
  change(val);
};

const handleControlOnChange = (controlId: string, val?: any) => {
  onChange(val);
};

const openSwatchesManagement = (optionType: SwatchesOptionType, optionTitle: string) => {
  settingSideBarStore.setSwatchesManagerData({
    openManageSwatches: true,
    optionType: optionType ?? '',
    optionTitle: optionTitle ?? '',
  });
};
</script>

<template>
  <div data-test="setting-layout">
    <SettingLayout
      :data-type="data.mapTo?.control.type"
      :is-full-width="isFullWidth"
      :layout="data.layout"
      :disabled="data.disabled"
      :styles="data.options?.styles"
      :is-control-auto-width="isControlAutoWidth"
      :label-with-lang="data.label"
      :help="data.help"
      :label-variant="data.options?.labelVariant || 'secondary'"
      :align="data.options?.labelAlign"
      :show-devices="hasDevices && !hiddenDevice && !data.options?.hiddenSwitchDeviceIcon"
      :label-show="!data?.options?.hideLabel && !data.options?.labelInsideControl">
      <template #tooltip>
        {{ data?.options?.disableMessage || 'Settings cannot be used at this time' }}
      </template>
      <template v-if="isLockedControl && data.label && nearestSupportedPlan" #locked>
        <ControlLocked
          :label="data.label?.en || ''"
          :nearest-supported-plan="nearestSupportedPlan"
          :control="data.mapTo?.control.type">
          <template #label>
            <SettingTitle :label-with-lang="data.label" :variant="data.options?.labelVariant || 'secondary'" />
          </template>
        </ControlLocked>
      </template>
      <template #control>
        <component
          v-bind="controlProps"
          :is="controlName"
          :disabled="data.disabled"
          @control-change="handleControlChange"
          @control-on-change="handleControlOnChange"
          @open-swatches-modal="openSwatchesManagement">
          <template v-if="data.options?.labelInsideControl" #label>
            <SettingTitle
              :label-with-lang="data.label"
              :help="data.help"
              :variant="data.options?.labelVariant || 'secondary'"
              :show-devices="hasDevices && !hiddenDevice"
              :current-device="currentDevice" />
          </template>
        </component>
      </template>
    </SettingLayout>
  </div>
</template>
