<script setup lang="ts">
import type { ScreenType } from '@gem/common';
import type { DisplayType, RowLayoutProps } from '../types';
import { ColumnWithSlider, SettingTitle, SettingLayout } from '@gem/element-setting-ui';
import { computed } from 'vue';
import type { LabelWithLang, LabelVariant, ActionSettingInput } from '@gem/element-setting-ui';
import LayoutRecommend from './LayoutRecommend.vue';
import MoreSettings from '../../../sidebar-setting/MoreSettings.vue';
import ColumnWidth from './ColumnWidth.vue';

import { useRowLayoutSettingsUpdate } from '../hooks/useRowLayoutSettingsUpdate';
import ColumnOrder from './ColumnOrder.vue';
import ColumnGap from './ColumnGap.vue';
import { useI18n } from '@gem/i18n';

const props = withDefaults(defineProps<RowLayoutProps>(), {
  currentScreen: 'desktop',
});

const emit = defineEmits<{
  (e: 'change-layout', value: number, device: ScreenType): void;
  (e: 'remove-item', id: string, device: ScreenType): void;
  (e: 'copy-item', id: string, device: ScreenType): void;
  (e: 'change-display', value: DisplayType, device: ScreenType): void;
  (e: 'change-gap', type: 'change' | 'onChange', value: string | undefined, device: ScreenType): void;
  (e: 'change-cols', type: 'change' | 'onChange', value: number[] | undefined, device: ScreenType): void;
  (e: 're-order', input: ActionSettingInput[]): void;
}>();

const {
  orderItems,
  maxColumn,
  isShowGapSetting,
  isShowColumnWithSlider,
  getValueByDevice,
  getReOrderValue,
  changeActiveDevice,
} = useRowLayoutSettingsUpdate(props);

const { $i18n } = useI18n();

const hiddenGapSetting = true;

const labelByDevice = computed(
  (): {
    label: LabelWithLang;
    labelPopover?: LabelWithLang;
    labelVariant: LabelVariant;
  } => {
    if (props.currentScreen === 'desktop')
      return {
        label: $i18n('control.desktopLayout'),
        labelPopover: $i18n('control.desktopLayout'),
        labelVariant: 'primary',
      };
    if (props.currentScreen === 'tablet')
      return {
        label: $i18n('control.tabletLayout'),
        labelPopover: $i18n('control.tabletLayout'),
        labelVariant: 'secondary',
      };
    return {
      label: $i18n('control.mobileLayout'),
      labelPopover: $i18n('control.mobileLayout'),
      labelVariant: 'secondary',
    };
  },
);

const value = computed(() => getValueByDevice(props.currentScreen));

const handleChangeLayout = (newCols: number) => {
  emit('change-layout', newCols, props.currentScreen);
};

const handleRemoveItem = (uid: string) => {
  emit('remove-item', uid, props.currentScreen);
};

const handleCopyItem = (uid: string) => {
  emit('copy-item', uid, props.currentScreen);
};

const handleReOrder = (data: { id?: string; title?: string }[]) => {
  emit('re-order', getReOrderValue(data, props.currentScreen));
};

const handleChangeDisplay = (displayType: DisplayType) => {
  emit('change-display', displayType, props.currentScreen);
};

const handleChangeGap = (type: 'change' | 'onChange', value?: string) => {
  emit('change-gap', type, value, props.currentScreen);
};

const handleChangeCols = (type: 'change' | 'onChange', value?: number[]) => {
  emit('change-cols', type, value, props.currentScreen);
};
</script>

<template>
  <div class="flex flex-col gap-8">
    <div class="flex w-full items-center justify-between">
      <SettingTitle :label="labelByDevice.label" :variant="labelByDevice.labelVariant" />
      <MoreSettings
        :data="{ type: 'icon', label: labelByDevice.labelPopover }"
        @open="() => changeActiveDevice(currentScreen)">
        <div class="flex flex-col gap-16">
          <template v-if="currentScreen !== 'desktop'">
            <SettingLayout
              v-if="isShowColumnWithSlider"
              layout="vertical"
              label-variant="secondary"
              :label-with-lang="{ en: 'Column width' }">
              <template #control>
                <ColumnWithSlider
                  :disabled="value?.display !== 'fill'"
                  :cols="value?.cols"
                  @control-change="(value) => handleChangeCols('change', value)"
                  @control-on-change="(value) => handleChangeCols('onChange', value)" />
              </template>
            </SettingLayout>
            <ColumnWidth :display-type="value?.display" @control-change="handleChangeDisplay" />
          </template>
          <ColumnOrder
            :childrens="childrens"
            :order-items="orderItems"
            :current-screen="currentScreen"
            :is-support-responsive-order="isSupportResponsiveOrder"
            @copy-item="handleCopyItem"
            @remove-item="handleRemoveItem"
            @on-re-order="handleReOrder" />
        </div>
      </MoreSettings>
    </div>
    <div class="flex flex-col gap-16">
      <LayoutRecommend :value="value" :device="currentScreen" :max-col="maxColumn" @change="handleChangeLayout" />
      <template v-if="currentScreen === 'desktop'">
        <ColumnWithSlider
          v-if="isShowColumnWithSlider"
          :disabled="value?.display !== 'fill'"
          :cols="value?.cols"
          @click="() => changeActiveDevice(currentScreen)"
          @control-change="(value) => handleChangeCols('change', value)"
          @control-on-change="(value) => handleChangeCols('onChange', value)" />
        <ColumnWidth :display-type="value?.display" @control-change="handleChangeDisplay" />
      </template>

      <ColumnGap
        v-if="isShowGapSetting && !hiddenGapSetting"
        :gap="value?.gap || '0px'"
        @control-change="(value) => handleChangeGap('change', value)"
        @control-on-change="(value) => handleChangeGap('onChange', value)" />
    </div>
  </div>
</template>
