<script setup lang="ts">
import { computed } from 'vue';
import type { DisplayType } from '../types';

import type { OptionItem } from '@gem/element-setting-ui';
import { SettingLayout, OptionValue } from '@gem/element-setting-ui';
import { useI18n } from '@gem/i18n';

type RowLayoutColumnWidthProps = {
  displayType?: DisplayType;
};

const props = defineProps<RowLayoutColumnWidthProps>();

const { $i18n } = useI18n();

const emit = defineEmits<(e: 'controlChange', displayType: DisplayType) => void>();
const displayValue = computed(() => {
  return props.displayType ?? 'fill';
});

const displayOptions: {
  label: string;
  value: DisplayType;
}[] = [
  {
    label: $i18n('yes'),
    value: 'fit',
  },
  {
    label: $i18n('no'),
    value: 'fill',
  },
];

const handleChangeDisplay = (value: OptionItem['value']) => {
  if (displayValue.value === value) return;
  emit('controlChange', value as DisplayType);
};
</script>

<template>
  <SettingLayout :label="$i18n('control.fitToContent')" label-variant="secondary">
    <template #control>
      <OptionValue
        id="displayType"
        :value="displayValue"
        :options="displayOptions"
        @control-change="handleChangeDisplay" />
    </template>
  </SettingLayout>
</template>
