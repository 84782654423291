import { computed } from 'vue';
import { getMessageForValidate, getMinValueForDiscountPrice, getNewRangeValue } from '../../common';
import { CompareType, DiscountType, TriggerType } from '../../constant';
import { CreationDateEmit, CreationDateProps } from '../types';

export const useDiscountPrice = (props: CreationDateProps, emit: CreationDateEmit) => {
  const typeOptions = [
    {
      label: '%',
      value: DiscountType.PERCENT,
    },
    {
      label: 'Fixed',
      value: DiscountType.FIXED,
    },
  ];

  const onChange = (_: string, value: DiscountType) => {
    emit('onUpdate', {
      ...props.data,
      discountType: value,
    });
  };

  const onChangeQuantityValue = (name: string, value: string) => {
    emit('onUpdate', {
      ...props.data,
      [name]: value,
    });
  };

  const min = computed(() => {
    const { conditionType } = props.data;
    return getMinValueForDiscountPrice(conditionType as CompareType);
  });

  const onChangeConditionType = (value: CompareType) => {
    const minCurr = getMinValueForDiscountPrice(value);
    const { from, to } = getNewRangeValue(props.data.from, props.data.to ?? '', minCurr, value);
    emit('onUpdate', {
      ...props.data,
      type: value,
      conditionType: value,
      from,
      to,
    });
  };

  const message = computed(() => {
    const { conditionType, from, to } = props.data;
    return getMessageForValidate({
      from,
      to,
      type: conditionType as CompareType,
      trigger: TriggerType.DISCOUNT_PRICE,
    });
  });

  return {
    typeOptions,
    onChange,
    onChangeQuantityValue,
    min,
    onChangeConditionType,
    message,
  };
};
