<script lang="ts" setup>
import { ProductTagEmit, ProductTagProps } from './types';
import { useProductTag } from './hooks/useProductTag';
import { GInput } from '@gem/uikit-v2';

const props = withDefaults(defineProps<ProductTagProps>(), {});
const emit = defineEmits<ProductTagEmit>();

const { onSelect, onSearch, uniqueTags, checkedTag, searchValue, isEmptyTags, pageUrl } = useProductTag(props, emit);
</script>

<template>
  <GInput
    :disable="isEmptyTags"
    :value="searchValue"
    input-style="normal"
    type="text"
    placeholder="Search tag"
    :classes="{
      'bg-dark-400 !text-dark-100': isEmptyTags,
    }"
    @on-change="onSearch">
    <template #icon>
      <GBaseIcon
        class="text-text-dark-500"
        :class="{
          '!text-dark-100': isEmptyTags,
        }"
        name="search"
        width="16"
        height="16" />
    </template>
  </GInput>
  <div class="max-h-[430px]">
    <div v-if="uniqueTags.length === 0" class="flex h-[228px] flex-col items-center justify-center space-y-12">
      <img alt="" src="../../../assets/empty_icon.png" />
      <p v-if="searchValue.trim().length > 0" class="text-12 text-text-dark-100 text-center">
        We couldn’t find any matches for
        <span class="line-clamp-2 text-light-200">"{{ searchValue }}".</span> Please check your search for any typos or
        try different keyword.
      </p>
      <p v-else class="text-12 text-text-dark-100 text-center">
        We couldn’t find any product tags. Please create on
        <a class="text-primary-250 no-underline" :href="pageUrl" target="_blank">Shopify</a>
      </p>
    </div>
    <perfect-scrollbar v-else class="max-h-[316px] max-w-[248px]">
      <GCheckbox
        v-for="tag in uniqueTags"
        :key="tag"
        :text="tag"
        :value="!!checkedTag.includes(tag)"
        class-container="pl-8 !w-fit py-6"
        input-classes="accent-primary-300 flex-shrink-0 relative after:content-[''] after:absolute after:w-full after:h-full after:bg-dark-500 after:border after:border-text-light-300 after:rounded-[2px] checked:after:hidden hover:accent-primary-300"
        label-classes="!text-text-dark-500 max-w-[224px] break-words text-12 leading-5 !pl-12"
        @on-change="(isChecked: boolean) => onSelect(isChecked, tag)" />
    </perfect-scrollbar>
  </div>
</template>
