<script setup lang="ts">
import type { ComboProps } from '@gem/element-setting-ui';
import { InputComboLayout } from '@gem/element-setting-ui';
import type { PickIconProps } from './types';
import { useIconPicker } from './hooks/useIconPicker';
import IconFilterSearch from './components/IconFilterSearch.vue';
import IconSuggestedList from './components/IconSuggestedList.vue';
import IconUploadList from './components/IconUploadList.vue';
import IconSystemList from './components/IconSystemList.vue';
import IconUploadButton from './components/IconUploadButton.vue';
import ConfirmDeleteIcon from './components/ConfirmDeleteIcon.vue';
import IconEmpty from './components/IconEmpty.vue';

const props = withDefaults(defineProps<PickIconProps & ComboProps>(), {
  allowedFiles: () => ['image/svg+xml'],
  maximumSize: 100 * 1024,
});
const emit = defineEmits<{
  (e: 'controlChange', value?: string, iconSource?: string): void;
  (e: 'show-more', source: string): void;
  (e: 'on-search', value: string): void;
  (e: 'on-change-type', val: string): void;
  (e: 'on-upload', formData: FormData): void;
  (e: 'on-delete-icon', ids: string[]): void;
  (e: 'show-error', msg: string): void;
}>();

const {
  list,
  deleteIds,
  comboSvg,
  modeDelete,
  searchValue,
  isEmptyIcon,
  styleOptions,
  wrapperProps,
  selectedItem,
  wrapperRefSub,
  containerProps,
  suggestedIcons,
  isUploadedActive,
  isShowUploadedIcons,
  isShowIconSystemList,
  isShowSuggestedIcons,
  contentDisplay,
  onSearch,
  handleClear,
  onSelectIcon,
  onSelectStyle,
  onBulkSelectIcon,
  onBulkDeleteIcon,
  onCancelDeleteIcon,
  handleSetDefaultValue,
  setActiveUploadedStyle,
  handleChangeFileFromUploader,
} = useIconPicker(props, emit);
</script>
<template>
  <div>
    <InputComboLayout
      :id="id"
      placeholder="Add..."
      :is-parent="true"
      :label="{ en: `Icon` }"
      :show-label="false"
      :is-full-width="true"
      :content-display="value ? contentDisplay : ''"
      :disable-scrollbar="true"
      :level="level || 0"
      :combo-svg="comboSvg"
      :combo-i-d="comboID || ''"
      :combo-parent-i-d="comboParentID || ''"
      :combo-root-parent-i-d="comboRootParentID || ''"
      @click="handleSetDefaultValue"
      @clear="handleClear">
      <div class="flex max-h-full flex-col justify-between">
        <div class="flex flex-col gap-12">
          <IconFilterSearch
            :options="styleOptions"
            :style-icon="styleIcon"
            :search-value="searchValue"
            @on-search="onSearch"
            @clear-search="() => (searchValue = '')"
            @on-select="onSelectStyle" />
          <div class="h-[300px] max-h-[calc(100vh_-_350px)]">
            <IconEmpty v-if="isEmptyIcon" :search-value="searchValue" />
            <div
              v-else
              v-bind="isUploadedActive ? null : containerProps"
              class="list-icon h-full w-[calc(100%_+_14px)] overflow-y-auto"
              data-test="editor-all-icon-listing">
              <div v-bind="wrapperProps" class="wrapper-list flex h-full !w-[calc(100%_-_8px)] flex-col gap-8">
                <IconSuggestedList
                  v-if="isShowSuggestedIcons"
                  :list-icon="suggestedIcons"
                  selected-item="selectedItem"
                  @on-select="(value: string, id: string) => onSelectIcon(value, id, 'system')" />
                <IconUploadList
                  v-if="isShowUploadedIcons"
                  :id="props.id"
                  :loading="props.loading"
                  :data="iconUploaded"
                  :selected-item="selectedItem"
                  :style-icon="styleIcon"
                  :is-active-uploaded="isUploadedActive"
                  :delete-ids="deleteIds"
                  @active-uploaded="setActiveUploadedStyle"
                  @mode-delete="(val) => (modeDelete = val)"
                  @bulk-select-icon="onBulkSelectIcon"
                  @on-select="(value: string, id: string) => onSelectIcon(value, id, 'uploaded')" />
                <IconSystemList
                  v-if="isShowIconSystemList"
                  :list-icon="list"
                  :selected-item="selectedItem"
                  @on-select="(value: string, id: string) => onSelectIcon(value, id, 'system')" />
              </div>
            </div>
          </div>
        </div>
        <IconUploadButton
          :input-ref="wrapperRefSub"
          :loading="isLoadingUpload"
          @upload-file="handleChangeFileFromUploader" />
        <ConfirmDeleteIcon v-if="modeDelete" @cancel="onCancelDeleteIcon" @ok="onBulkDeleteIcon" />
      </div>
    </InputComboLayout>
  </div>
</template>
<style lang="postcss" scoped>
@keyframes placeHolderShimmer {
  0% {
    background-position: -150px 0;
  }
  100% {
    background-position: 100px 0;
  }
}
.animated-background {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #494949 10%, #2c2c2c 18%, #494949 33%);
  background-size: 800px 100%;
  position: relative;
}

.dropdown-style-icon-picker {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 8px 16px 2px rgba(0, 0, 0, 0.24);
}

.list-icon::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
}

.list-icon::-webkit-scrollbar-thumb:vertical:hover {
  width: 12px;
  cursor: pointer;
}

.list-icon::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 10px;
  width: 16px;
}

.list-icon::-webkit-scrollbar-thumb {
  min-height: 20px;
}

.list-icon::-webkit-scrollbar-thumb {
  border-radius: 10px;
  @apply bg-text-dark-300;
}
</style>
