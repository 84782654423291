<script setup lang="ts">
import { computed } from 'vue';
import { useControlChange } from '../../sidebar-setting/hooks/useControlChange';
import { WrapPositionControlTypes, PositionType } from './types';
import SettingLayout from '../../sidebar-setting/layout/SettingLayout.vue';
import PositionControl from './PositionControl.vue';
import InputWithUnits from '../inputs/InputWithUnits.vue';
const props = defineProps<WrapPositionControlTypes>();

const emits = defineEmits<{
  (e: 'controlChange'): void;
}>();

const controlChangeData = computed(() => props.controlChangeData);
const { change } = useControlChange(controlChangeData);
const placement = computed(() => props.value?.placement);

const onChange = (newValue?: string) => {
  if (!props.value) return;
  change({
    ...props.value,
    placement: newValue,
  });
  emits('controlChange');
};

const onDeferChange = (key?: string, newValue?: string) => {
  let validatedValue = newValue;
  const numberValue = newValue?.replace('px', '');
  if (!Number.isInteger(Number(numberValue)) || Number(numberValue) < 0 || !key) {
    return;
  }

  change({
    ...props.value,
    [key]: validatedValue,
  });
};

const onBlur = (key: string, newValue?: string) => {
  if (newValue === props.value?.[key as keyof PositionType]) {
    return;
  }

  if (newValue?.length === 0) {
    return change({
      ...props.value,
      [key]: '0px',
    });
  }

  const numberValue = newValue?.replace('px', '');
  change({
    ...props.value,
    [key]:
      !Number.isInteger(Number(numberValue)) || Number(numberValue) < 0
        ? props.value?.[props.id as keyof PositionType]
        : newValue,
  });
};

const gap: Record<keyof Omit<PositionType, 'placement'>, { label: string; availablePosition: string[] }> = {
  topGap: {
    label: 'Top gap',
    availablePosition: ['top', 'top-left', 'top-right'],
  },
  bottomGap: {
    label: 'Bottom gap',
    availablePosition: ['bottom', 'bottom-left', 'bottom-right'],
  },
  leftGap: {
    label: 'Left gap',
    availablePosition: ['left', 'top-left', 'bottom-left'],
  },
  rightGap: {
    label: 'Right gap',
    availablePosition: ['right', 'top-right', 'bottom-right'],
  },
};
</script>

<template>
  <SettingLayout :label="{ en: 'Choose' }" label-variant="secondary">
    <template #control>
      <PositionControl
        id="placement"
        :value="placement"
        :ignore-value="ignoreValue"
        class="mb-16 flex items-start"
        @control-change="onChange">
      </PositionControl>
    </template>
  </SettingLayout>

  <template v-for="(gapType, key) in gap" :key="key">
    <div
      v-if="gapType.availablePosition.includes(placement || '')"
      class="gemx-control mb-16 flex min-h-[36px] w-full items-center justify-between gap-16">
      <SettingLayout :label="{ en: gapType.label }" label-variant="secondary">
        <template #control>
          <div class="w-[140px] shrink-0">
            <InputWithUnits
              :id="key"
              :value="value?.[key]"
              :units="['px']"
              :empty-on-clear="true"
              :elm-disable-blur-action="['.disable-blur-when-click']"
              :control-change="onBlur"
              input-class="!w-full"
              :is-hidden-value="true"
              @control-on-change="onDeferChange" />
          </div>
        </template>
      </SettingLayout>
    </div>
  </template>
</template>
