<script setup lang="ts">
import type { GInputProps } from '@gem/uikit-v2';
import { GInput, GButton } from '@gem/uikit-v2';
import type { Unit } from '@gem/element-setting-ui';
import { useInputUnit } from './hooks/useInputUnit';
import { computed } from 'vue';
type PropsType = GInputProps & {
  id: string;
  max?: number;
  min?: number;
  units: Unit[];
  defaultValue?: string;
  isHiddenValue?: boolean;
};

const props = defineProps<PropsType>();

const emit = defineEmits<{
  (e: 'controlChange', controlId?: string, value?: string): void;
  (e: 'controlOnChange', controlId?: string, value?: string): void;
}>();

const data = computed(() => props);

const { currentUnit, inputValue, valueChange, inputRef, updateUnit, updateValueChange } = useInputUnit(data);

const handleControlOnChange = () => {
  updateValueChange();
  emit('controlOnChange', props.id, valueChange.value);
};

const handleControlChange = () => {
  updateValueChange({ isUpdateInput: true });
  emit('controlChange', props.id, valueChange.value);
};

const handleChangeUnit = (unit: Unit) => {
  updateUnit(unit);
  emit('controlChange', props.id, valueChange.value);
};
</script>
<template>
  <div class="gem-control relative">
    <template v-if="!isHiddenValue">
      {{ value }}
    </template>
    <GInput
      ref="inputRef"
      v-bind="props"
      type="number"
      :value="inputValue"
      @on-change="handleControlOnChange"
      @click-out-side="handleControlChange" />
    <div v-if="units.length" class="absolute top-1/2 right-8 flex -translate-y-1/2 gap-4">
      <GButton
        v-for="(unit, index) in units"
        :key="'unit' + index"
        :subdued="currentUnit !== unit"
        type="ghost"
        size="small"
        :is-square="true"
        @click="() => handleChangeUnit(unit)"
        >{{ unit }}</GButton
      >
    </div>
  </div>
</template>
