import { IconName } from '@gem/icons';
import type { DisplayTrigger } from './types';

export enum TriggerType {
  INVENTORY_STATUS = 'inventory_status',
  DISCOUNT_PRICE = 'discount_price',
  PRICE_RANGE = 'price_range',
  PRODUCT_TAGS = 'product_tags',
  CREATION_DATE = 'creation_date',
}

export const triggerTypeLabel: Record<TriggerType, string> = {
  [TriggerType.INVENTORY_STATUS]: 'Product inventory',
  [TriggerType.DISCOUNT_PRICE]: 'Discount price',
  [TriggerType.PRICE_RANGE]: 'Price range',
  [TriggerType.PRODUCT_TAGS]: 'Product tags',
  [TriggerType.CREATION_DATE]: 'Created date',
};

export const settingLayoutLabel: Record<TriggerType, string> = {
  [TriggerType.INVENTORY_STATUS]: 'Inventory',
  [TriggerType.DISCOUNT_PRICE]: 'Discount',
  [TriggerType.PRICE_RANGE]: 'Price range',
  [TriggerType.PRODUCT_TAGS]: 'Product tag',
  [TriggerType.CREATION_DATE]: 'Created date',
};

export const settingLayoutIcon: Record<TriggerType, IconName> = {
  [TriggerType.INVENTORY_STATUS]: 'gp-inventory-condition',
  [TriggerType.DISCOUNT_PRICE]: 'gp-discount-condition',
  [TriggerType.PRICE_RANGE]: 'gp-price-range-condition',
  [TriggerType.PRODUCT_TAGS]: 'gp-product-tag-condition',
  [TriggerType.CREATION_DATE]: 'gp-created-date-condition',
};

export const triggerComboIcon: Record<TriggerType, string> = {
  [TriggerType.INVENTORY_STATUS]: 'Inventory',
  [TriggerType.DISCOUNT_PRICE]: 'Discount',
  [TriggerType.PRICE_RANGE]: 'Price range',
  [TriggerType.PRODUCT_TAGS]: 'Product tag',
  [TriggerType.CREATION_DATE]: 'Created date',
};

export enum CompareType {
  EXACT = 'exact',
  ABOVE = 'above',
  BELOW = 'below',
  EXACT_OR_ABOVE = 'exact_or_above',
  EXACT_OR_BELOW = 'exact_or_below',
  BETWEEN = 'between',
}

export enum InventoryStatus {
  IN_STOCK = 'in_stock',
  OUT_OF_STOCK = 'out_of_stock',
}

export enum CreationDateType {
  DURATION = 'duration',
  EXACT_DATE = 'exact_date',
  BETWEEN_DATES = 'between_dates',
}

export enum DiscountType {
  PERCENT = 'percent',
  FIXED = 'fixed',
}

export const COMPARE_OPTIONS = [
  { id: CompareType.EXACT, icon: 'equal' },
  { id: CompareType.ABOVE, icon: 'bigger' },
  { id: CompareType.BELOW, icon: 'smaller' },
  { id: CompareType.EXACT_OR_ABOVE, icon: 'equal-or-bigger' },
  { id: CompareType.EXACT_OR_BELOW, icon: 'equal-or-smaller' },
  { id: CompareType.BETWEEN, icon: 'between' },
];

export const conditionProductBadgeDefault: Record<TriggerType, DisplayTrigger> = {
  [TriggerType.INVENTORY_STATUS]: {
    triggerEvent: TriggerType.INVENTORY_STATUS,
    type: InventoryStatus.IN_STOCK,
    quantityType: CompareType.EXACT_OR_ABOVE,
    from: '1',
    to: '15',
    priority: 0,
    conditionType: CompareType.EXACT_OR_ABOVE,
  },
  [TriggerType.DISCOUNT_PRICE]: {
    triggerEvent: TriggerType.DISCOUNT_PRICE,
    type: CompareType.EXACT,
    amount: 'Any',
    from: '15',
    to: '17',
    priority: 1,
    discountType: DiscountType.PERCENT,
    conditionType: CompareType.EXACT,
  },
  [TriggerType.PRICE_RANGE]: {
    triggerEvent: TriggerType.PRICE_RANGE,
    from: '1',
    to: '40',
    priority: 2,
    conditionType: CompareType.EXACT_OR_ABOVE,
  },
  [TriggerType.PRODUCT_TAGS]: {
    triggerEvent: TriggerType.PRODUCT_TAGS,
    searchTag: [],
    priority: 3,
    conditionType: CompareType.EXACT,
  },
  [TriggerType.CREATION_DATE]: {
    triggerEvent: TriggerType.CREATION_DATE,
    type: CreationDateType.DURATION,
    days: '7',
    from: '',
    to: '',
    priority: 4,
    conditionType: CompareType.BETWEEN,
  },
};
