<script lang="ts" setup>
import type { ActionSettingInput } from '@gem/element-setting-ui';
import { SettingTitle } from '@gem/element-setting-ui';
import { useRowLayoutSettingsUpdate } from './hooks/useRowLayoutSettingsUpdate';
import type { GridSettingInput, RowLayoutProps } from './types';
import DeviceSettings from './components/DeviceSettings.vue';
import type { ScreenType } from '@gem/common';
import { useI18n } from '@gem/i18n';

const emit = defineEmits<{
  (
    e: 'control-change',
    id: RowLayoutProps['id'],
    value: RowLayoutProps['value'] | undefined,
    screenId?: ScreenType,
  ): void;
  (
    e: 'control-on-change',
    id: RowLayoutProps['id'],
    value: RowLayoutProps['value'] | undefined,
    screenId?: ScreenType,
  ): void;
  (e: 'control-change-children', input: ActionSettingInput[], device: ScreenType): void;
  (e: 'control-change-layout', input: GridSettingInput[]): void;
  (e: 'remove-item', uid: string): void;
  (e: 'copy-item', uid: string): void;
  (e: 'add-item'): void;
}>();

const { $i18n } = useI18n();

const props = withDefaults(defineProps<RowLayoutProps>(), {
  maxCol: 6,
  currentScreen: 'desktop',
  isSupportResponsiveOrder: true,
});

const {
  handleChangeLayout,
  handleReOrder,
  handleUpdateCols,
  handleChangeDisplay,
  handleChangeGap,
  handleRemoveItem,
  handleCopyItem,
} = useRowLayoutSettingsUpdate(props, emit);
</script>

<template>
  <div class="w-full" data-text="row-layout-control">
    <slot name="label"></slot>
    <div class="flex flex-col gap-16">
      <div class="border-dark-300 border-b pb-16">
        <DeviceSettings
          key="desktop"
          v-bind="props"
          current-screen="desktop"
          @copy-item="handleCopyItem"
          @remove-item="handleRemoveItem"
          @change-layout="handleChangeLayout"
          @change-cols="handleUpdateCols"
          @change-display="handleChangeDisplay"
          @change-gap="handleChangeGap"
          @re-order="handleReOrder" />
      </div>
      <div>
        <SettingTitle :label="$i18n('control.responsiveLayout')" variant="primary" />
        <div class="flex flex-col gap-16">
          <DeviceSettings
            key="tablet"
            v-bind="props"
            current-screen="tablet"
            @change-layout="handleChangeLayout"
            @change-cols="handleUpdateCols"
            @change-display="handleChangeDisplay"
            @change-gap="handleChangeGap"
            @re-order="handleReOrder" />
          <DeviceSettings
            key="mobile"
            v-bind="props"
            current-screen="mobile"
            @change-layout="handleChangeLayout"
            @change-cols="handleUpdateCols"
            @change-display="handleChangeDisplay"
            @change-gap="handleChangeGap"
            @re-order="handleReOrder" />
        </div>
      </div>
    </div>
  </div>
</template>
