import { computed, ComputedRef } from 'vue';
import { CompareType, CreationDateType, DiscountType, InventoryStatus, TriggerType } from '../constant';
import dayjs from '@gem/uikit/src/helpers/dayjs';
import { DisplayTrigger } from '../types';
import { formatTitleCurrency, getLabel, getSubTitle } from '../common';

export const useGetSubtitle = (
  triggerValue: ComputedRef<Record<TriggerType, DisplayTrigger | undefined>>,
  moneyFormat?: string,
) => {
  const inventoryStatusSubtitle = computed(() => {
    const data = triggerValue.value[TriggerType.INVENTORY_STATUS];
    if (data?.triggerEvent !== TriggerType.INVENTORY_STATUS) return '';

    const { type, quantityType, from, to } = data;
    const fromValue = Number(from);
    if (type === InventoryStatus.OUT_OF_STOCK) return getLabel(InventoryStatus.OUT_OF_STOCK);
    if (quantityType === CompareType.EXACT_OR_ABOVE && fromValue >= 1) return 'In stock';
    if (
      (quantityType === CompareType.BELOW && fromValue === 2) ||
      (quantityType === CompareType.EXACT_OR_BELOW && fromValue === 1)
    )
      return 'Exact 1';
    if (quantityType === CompareType.BELOW && fromValue >= 3) return `From 1 to ${fromValue - 1}`;
    if (quantityType === CompareType.EXACT_OR_BELOW) return `From 1 to ${fromValue}`;
    return getSubTitle(quantityType, from, to, '{{amount}}');
  });

  const discountPriceSubtitle = computed(() => {
    const data = triggerValue.value[TriggerType.DISCOUNT_PRICE];
    if (data?.triggerEvent !== TriggerType.DISCOUNT_PRICE) return '';
    const { discountType, conditionType, from, to } = data;
    const isPercentage = discountType === DiscountType.PERCENT;
    return getSubTitle(conditionType, from, to, isPercentage ? '{{amount}}%' : moneyFormat);
  });

  const priceRangeSubtitle = computed(() => {
    const data = triggerValue.value[TriggerType.PRICE_RANGE];
    if (data?.triggerEvent !== TriggerType.PRICE_RANGE) return '';
    const { conditionType, from, to } = data;
    if (conditionType === CompareType.EXACT_OR_BELOW && Number(from) === 0) {
      const value = formatTitleCurrency(moneyFormat, from);
      return `Exact ${value}`;
    }
    return getSubTitle(conditionType, from, to, moneyFormat);
  });

  const productTagSubtitle = computed(() => {
    const data = triggerValue.value[TriggerType.PRODUCT_TAGS];
    if (data?.triggerEvent !== TriggerType.PRODUCT_TAGS) return '';
    if (data.searchTag.length > 0) return data.searchTag.map((el) => el).join(', ');
    return 'Add tags';
  });

  const creationDateSubtitle = computed(() => {
    const data = triggerValue.value[TriggerType.CREATION_DATE];
    if (data?.triggerEvent !== TriggerType.CREATION_DATE) return '';
    const { days, from, to, type } = data;
    if (type === CreationDateType.DURATION) {
      if (Number(days) <= 7) return 'A week ago';
      return `${days} days ago`;
    }
    if (type === CreationDateType.EXACT_DATE && from) {
      return `On ${dayjs(from).format('MMM DD YYYY')}`;
    }
    if (type === CreationDateType.BETWEEN_DATES) {
      if (!from && !to) return 'Select date';
      if (!from || !to) return 'Unknown date';
      return `${from ? dayjs(from).format('MMM DD YYYY') : ''} - ${to ? dayjs(to).format('MMM DD YYYY') : ''}`;
    }
    return 'Select date';
  });

  return {
    [TriggerType.INVENTORY_STATUS]: inventoryStatusSubtitle,
    [TriggerType.DISCOUNT_PRICE]: discountPriceSubtitle,
    [TriggerType.PRICE_RANGE]: priceRangeSubtitle,
    [TriggerType.PRODUCT_TAGS]: productTagSubtitle,
    [TriggerType.CREATION_DATE]: creationDateSubtitle,
  };
};
