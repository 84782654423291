import { computed, ref, watch } from 'vue';
import { handleSearch, handleUnique } from '../../common';
import { ProductTagProps, ProductTagEmit } from '../types';
import { useDebounceFn } from '@vueuse/core';

export const useProductTag = (props: ProductTagProps, emit: ProductTagEmit) => {
  const searchValue = ref('');
  const checkedTag = ref<string[]>(props.data.searchTag);
  const uniqueTags = ref<string[]>(props.productTags || []);

  const onSearch = useDebounceFn((value: string) => {
    searchValue.value = value;
    uniqueTags.value = handleSearch(handleUnique(props.productTags || []), value);
    emit('onSearch', value);
  }, 500);

  const onSelect = (isChecked: boolean, value: string) => {
    if (!isChecked) {
      checkedTag.value = checkedTag.value.filter((tag) => tag !== value);
    } else {
      checkedTag.value = [...checkedTag.value, value];
    }
    emit('onUpdate', {
      ...props.data,
      searchTag: checkedTag.value,
    });
  };

  watch(
    () => props.data,
    (newValue) => {
      if (newValue) {
        checkedTag.value = newValue.searchTag;
      }
    },
    {
      immediate: true,
    },
  );

  watch(
    () => props.productTags,
    (val) => {
      if (val) uniqueTags.value = handleUnique(val);
    },
    { immediate: true },
  );

  const pageUrl = computed(() => `https://${props.shopDomain}/admin/products`);

  const isEmptyTags = computed(() => uniqueTags.value.length === 0 && searchValue.value.trim().length === 0);

  return {
    onSelect,
    onSearch,
    uniqueTags,
    checkedTag,
    searchValue,
    isEmptyTags,
    pageUrl,
  };
};
