import { onClickOutside } from '@vueuse/core';
import { computed, ref, watch } from 'vue';
import { getMessageForValidate, getMinValueForInventory, getNewRangeValue } from '../../common';
import { CompareType, InventoryStatus, TriggerType } from '../../constant';
import { InventoryStatusEmit, InventoryStatusProps } from '../types';

export const useInventoryStatus = (props: InventoryStatusProps, emit: InventoryStatusEmit) => {
  const triggerButton = ref(null);
  const isOpenDropdown = ref(false);
  const type = ref<InventoryStatus>(props.data.type);
  const quantityType = ref<CompareType>(props.data.quantityType);

  watch(
    () => props.data,
    (data) => {
      type.value = data.type;
      quantityType.value = data.quantityType;
    },
    { immediate: true },
  );

  const onChangeType = (_: string, value: InventoryStatus) => {
    emit('onUpdate', {
      ...props.data,
      type: value,
    });
  };

  const onChangeQuantityType = (value: CompareType) => {
    const minCurr = getMinValueForInventory(value);
    const { from, to } = getNewRangeValue(props.data.from, props.data.to ?? '', minCurr, value);
    emit('onUpdate', {
      ...props.data,
      quantityType: value,
      conditionType: value,
      from,
      to,
    });
  };

  const onChangeQuantityValue = (name: string, value: string) => {
    emit('onUpdate', {
      ...props.data,
      [name]: value,
    });
  };

  onClickOutside(triggerButton, () => {
    isOpenDropdown.value = false;
  });

  const message = computed(() => {
    const { conditionType, from, to } = props.data;
    return getMessageForValidate({
      from,
      to,
      type: conditionType as CompareType,
      trigger: TriggerType.INVENTORY_STATUS,
    });
  });

  const min = computed(() => {
    const { conditionType } = props.data;
    return getMinValueForInventory(conditionType as CompareType);
  });
  return {
    onChangeType,
    onChangeQuantityType,
    onChangeQuantityValue,
    message,
    min,
    isOpenDropdown,
    type,
    quantityType,
  };
};
