import { computed } from 'vue';
import { getMinValueForPrice, getNewRangeValue, getMessageForValidate } from '../../common';
import { CompareType, TriggerType } from '../../constant';
import { PriceRangeProps, PriceRangeEmit } from '../types';

export const usePriceRange = (props: PriceRangeProps, emit: PriceRangeEmit) => {
  const min = computed(() => getMinValueForPrice(props.data.conditionType as CompareType));

  const onSelect = (value: CompareType) => {
    const minCurr = getMinValueForPrice(value);
    const { from, to } = getNewRangeValue(props.data.from, props.data.to ?? '', minCurr, value);
    emit('onUpdate', {
      ...props.data,
      conditionType: value,
      from,
      to,
    });
  };

  const onChangeQuantityValue = (name: string, value: string) => {
    emit('onUpdate', {
      ...props.data,
      [name]: value,
    });
  };

  const message = computed(() => {
    const { conditionType, from, to } = props.data;
    return getMessageForValidate({
      from,
      to,
      type: conditionType as CompareType,
      trigger: TriggerType.PRICE_RANGE,
    });
  });

  return {
    onSelect,
    min,
    message,
    onChangeQuantityValue,
  };
};
