<script setup lang="ts">
import { GBanner } from '@gem/uikit-v2';
import type { ImageSettingProps } from './types';
import { InputComboLayout, SettingLayout, Toggle, TextareaWithAction } from '@gem/element-setting-ui';
import { computed, ref, nextTick } from 'vue';
import { InputText } from '@gem/element-setting-ui';

const props = defineProps<ImageSettingProps>();
const val = computed(() => props.value);

const isActiveSourceInput = ref(false);

const emit = defineEmits<{
  (e: 'handleControlChange', value?: any): void;
  (e: 'handleControlOnChange', value?: any): void;
}>();

const handleChangeValue = (type: string, value?: any) => {
  emit('handleControlChange', { ...val.value, [type]: value });
};

const handleChange = (value?: any) => {
  emit('handleControlChange', value);
};

const fixedValue = {
  src: 'https://ucarecdn.com/5e27d43d-eb1e-4e71-b7e1-4158f3071bb6/-/format/auto/',
  width: 1200,
  height: 480,
};

const defaultValue = computed(() => {
  return props.compoDefaultValue || fixedValue;
});

const handleSetDefaultValue = () => {
  if (!val.value?.src) handleChange(defaultValue.value);

  isActiveSourceInput.value = false;

  nextTick(() => {
    isActiveSourceInput.value = true;
  });
};

const handleClear = () => {
  handleChange({
    ...val.value,
    src: '',
  });
};
</script>
<template>
  <div>
    <InputComboLayout
      :id="id"
      placeholder="Add..."
      :is-parent="true"
      :label="label ?? { en: 'Image' }"
      :show-label="false"
      :is-full-width="true"
      :combo-image="value?.src"
      :content-display="value?.src"
      :level="level || 0"
      :combo-i-d="comboID || ''"
      :combo-parent-i-d="comboParentID || ''"
      :combo-root-parent-i-d="comboRootParentID || ''"
      @click="handleSetDefaultValue"
      @clear="handleClear">
      <div class="flex flex-col gap-12">
        <div class="border-dark-300 flex flex-col gap-12 border-b pb-4">
          <SettingLayout layout="vertical" :label-with-lang="{ en: 'Source' }" label-variant="primary">
            <template #control>
              <slot v-bind="{ isActiveSourceInput }"></slot>
            </template>
          </SettingLayout>
        </div>
        <div class="border-dark-300 flex flex-col gap-12 border-b pb-20">
          <SettingLayout layout="vertical" :label-with-lang="{ en: 'SEO' }" label-variant="primary">
            <template #control>
              <div class="flex flex-col gap-16">
                <SettingLayout :label-with-lang="{ en: 'Alt text' }" align="top">
                  <template #control>
                    <TextareaWithAction
                      id="altText"
                      :value="val?.altText"
                      :min-height="78"
                      placeholder="E.g: Classic white crewneck t-shirt with minimalist design"
                      @control-change="(value?: string) => handleChangeValue('altText', value)" />
                  </template>
                </SettingLayout>
                <SettingLayout :label-with-lang="{ en: 'Image title' }">
                  <template #control>
                    <InputText
                      id="imageTitle"
                      :value="val?.imageTitle"
                      placeholder="E.g: White t-shirt"
                      @control-change="(value) => handleChangeValue('imageTitle', value)" />
                  </template>
                </SettingLayout>
              </div>
            </template>
          </SettingLayout>
        </div>
        <SettingLayout layout="vertical" :label-with-lang="{ en: 'Optimize LCP' }" label-variant="primary">
          <template #control>
            <GBanner
              type="info"
              message="You can optimize image size with"
              :link="{ label: `our partner app.`, url: linkToDashboard }" />
            <SettingLayout :label-with-lang="{ en: 'Preload' }">
              <template #control>
                <Toggle
                  :value="val?.preload || false"
                  @control-change="(value?: boolean) => handleChangeValue('preload', value)"
              /></template>
            </SettingLayout>
          </template>
        </SettingLayout>
      </div>
    </InputComboLayout>
  </div>
</template>
